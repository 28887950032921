module.exports = {
  "hierarchy": {
    "small": [
      "portal-small"
    ],
    "mobileMenu": [
      "portal-mobileMenu"
    ],
    "dialog": [
      "portal-dialog"
    ],
    "confirmContactDelete": [
      "portal-confirmContactDelete"
    ],
    "exceptionDetail": [
      "portal-exceptionDetail"
    ]
  },
  "props": {
    "default": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "small": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "mobileMenu": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "dialog": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "confirmContactDelete": {
      "animation": "slideFromTop",
      "position": "top"
    },
    "exceptionDetail": {
      "animation": "slideFromTop",
      "position": "top"
    }
  }
}