module.exports = {
  "hierarchy": {
    "small": [
      "button-small"
    ],
    "hollow": [
      "button-hollow"
    ],
    "smallHollow": [
      "button-hollow",
      "button-smallHollow"
    ],
    "link": [
      "button-hollow",
      "button-link"
    ],
    "smallLink": [
      "button-hollow",
      "button-link",
      "button-smallLink"
    ],
    "icon": [
      "button-hollow",
      "button-link",
      "button-icon"
    ],
    "close": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-close"
    ],
    "danger": [
      "button-hollow",
      "button-danger"
    ],
    "smallDanger": [
      "button-hollow",
      "button-smallHollow",
      "button-smallDanger"
    ],
    "search": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-search"
    ],
    "helpButton": [
      "button-hollow",
      "button-link",
      "button-smallLink",
      "button-helpButton"
    ],
    "dealerButton": [
      "button-dealerButton"
    ],
    "cancel": [
      "button-cancel"
    ],
    "menu": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-search",
      "button-searchMobile",
      "button-menu"
    ],
    "searchMobile": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-search",
      "button-searchMobile"
    ],
    "prev": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-close",
      "button-prev"
    ],
    "next": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-close",
      "button-prev",
      "button-next"
    ],
    "closeLight": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-close",
      "button-closeLight"
    ],
    "add": [
      "button-add"
    ],
    "tableLink": [
      "button-tableLink"
    ],
    "bigBack": [
      "button-bigBack"
    ],
    "vinButton": [
      "button-hollow",
      "button-link",
      "button-vinButton"
    ],
    "cancelContactDelete": [
      "button-cancel",
      "button-cancelContactDelete"
    ],
    "confirmContactDelete": [
      "button-confirmContactDelete"
    ],
    "logout": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-logout"
    ],
    "zoomButton": [
      "button-zoomButton"
    ],
    "logoutMobile": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-logoutMobile"
    ],
    "vehicleDrillDownTab": [
      "button-vehicleDrillDownTab"
    ],
    "vehicleDrillDownTabActive": [
      "button-vehicleDrillDownTabActive"
    ],
    "eventAck": [
      "button-eventAck"
    ],
    "createNewEvent": [
      "button-createNewEvent"
    ],
    "closeStatusPane": [
      "button-closeStatusPane"
    ],
    "statusPaneCancel": [
      "button-cancel",
      "button-statusPaneCancel"
    ],
    "statusPaneSave": [
      "button-statusPaneSave"
    ],
    "zoomButtonPlus": [
      "button-zoomButton",
      "button-zoomButtonPlus"
    ],
    "zoomButtonMinus": [
      "button-zoomButton",
      "button-zoomButtonMinus"
    ],
    "dialogCancel": [
      "button-dialogCancel"
    ],
    "dialogDelete": [
      "button-dialogDelete"
    ],
    "detailedHistoryButton": [
      "button-detailedHistoryButton"
    ],
    "historyPagination": [
      "button-closeStatusPane",
      "button-historyPagination"
    ],
    "exportCSV": [
      "button-exportCSV"
    ],
    "clearSearch": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-clearSearch"
    ],
    "clearSearchMobile": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-clearSearchMobile"
    ],
    "searchInfo": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-searchInfo"
    ],
    "crazyButton": [
      "button-crazyButton"
    ],
    "vinLink": [
      "button-hollow",
      "button-link",
      "button-vinButton",
      "button-vinLink"
    ],
    "modalCancel": [
      "button-hollow",
      "button-link",
      "button-modalCancel"
    ],
    "modalConfirm": [
      "button-modalConfirm"
    ],
    "homeButton": [
      "button-homeButton"
    ],
    "homeButtonHollow": [
      "button-hollow",
      "button-homeButtonHollow"
    ],
    "linkDanger": [
      "button-hollow",
      "button-link",
      "button-linkDanger"
    ],
    "linkNoPad": [
      "button-hollow",
      "button-link",
      "button-linkNoPad"
    ],
    "fixedWidth": [
      "button-fixedWidth"
    ],
    "linkFat": [
      "button-hollow",
      "button-link",
      "button-linkFat"
    ],
    "closeToast": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-close",
      "button-closeToast"
    ],
    "vinButtonLarge": [
      "button-hollow",
      "button-link",
      "button-vinButton",
      "button-vinButtonLarge"
    ],
    "dateRangePickerCancel": [
      "button-hollow",
      "button-dateRangePickerCancel"
    ],
    "toggleResults": [
      "button-toggleResults"
    ],
    "scanDevice": [
      "button-hollow",
      "button-link",
      "button-icon",
      "button-scanDevice"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "hollow": {},
    "smallHollow": {},
    "link": {},
    "smallLink": {},
    "icon": {
      "icon": "fa fa-sign-out "
    },
    "close": {
      "icon": "fa fa-times"
    },
    "danger": {},
    "smallDanger": {},
    "search": {
      "icon": "fa fa-search"
    },
    "helpButton": {},
    "dealerButton": {},
    "cancel": {},
    "menu": {
      "icon": "fa fa-bars"
    },
    "searchMobile": {
      "icon": "fa fa-search"
    },
    "prev": {
      "icon": "fa fa-chevron-left"
    },
    "next": {
      "icon": "fa fa-chevron-right"
    },
    "closeLight": {
      "icon": "fa fa-times"
    },
    "add": {
      "icon": "fa fa-plus"
    },
    "tableLink": {},
    "bigBack": {
      "icon": "fa fa-arrow-left"
    },
    "vinButton": {},
    "cancelContactDelete": {},
    "confirmContactDelete": {},
    "logout": {
      "icon": "fa fa-sign-out "
    },
    "zoomButton": {},
    "logoutMobile": {
      "icon": "fa fa-sign-out "
    },
    "vehicleDrillDownTab": {},
    "vehicleDrillDownTabActive": {},
    "eventAck": {},
    "createNewEvent": {},
    "closeStatusPane": {},
    "statusPaneCancel": {},
    "statusPaneSave": {},
    "zoomButtonPlus": {
      "icon": "icon ftr-plus"
    },
    "zoomButtonMinus": {
      "icon": "icon ftr-minus"
    },
    "dialogCancel": {},
    "dialogDelete": {},
    "detailedHistoryButton": {},
    "historyPagination": {},
    "exportCSV": {},
    "clearSearch": {
      "icon": "fa fa-times"
    },
    "clearSearchMobile": {
      "icon": "fa fa-times"
    },
    "searchInfo": {
      "icon": "fa fa-question-circle"
    },
    "crazyButton": {},
    "vinLink": {},
    "modalCancel": {},
    "modalConfirm": {},
    "homeButton": {},
    "homeButtonHollow": {},
    "linkDanger": {},
    "linkNoPad": {},
    "fixedWidth": {},
    "linkFat": {},
    "closeToast": {
      "icon": ""
    },
    "vinButtonLarge": {},
    "dateRangePickerCancel": {},
    "toggleResults": {},
    "scanDevice": {
      "icon": "fa fa-sign-out "
    }
  }
}