module.exports = {
  "hierarchy": {
    "overdueAlerts": [
      "table-overdueAlerts"
    ],
    "history": [
      "table-history"
    ],
    "exceptions": [
      "table-exceptions"
    ]
  },
  "props": {
    "default": {},
    "overdueAlerts": {},
    "history": {},
    "exceptions": {}
  }
}