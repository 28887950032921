function isTracker( input ) {
  const trackNetDeviceIds = ( process.env.TRACKNET_DEVICE_ID_PREFIXES || '58A0CB' ).split( ',' ).map( prefix => prefix.trim() )

  return trackNetDeviceIds.some( ( trackerNetDeviceId ) => {
    return input && input.toUpperCase().startsWith( trackerNetDeviceId )
  } )
}

function isAlphaNum( input ) {
  const char = String.fromCharCode( input.which ) // get character from key code
  return !!char.match( /^[a-z0-9]+$/i ) // returns true if input is alphanum char a-z || A-Z || 0-9, else false
}

/**
 * returns string truncated to 17 chars if initial string is 17-20 chars
 * used to remove manf. specific variations from US VIN
 * see https://github.com/mikebuss/MTBBarcodeScanner/issues/27
 * @param {string} vin 
 */
function to17CharVin( vin ) {
  switch ( vin.length ) {
    case 18: 
      return vin.slice( 1 );

    case 19: 
      return vin.slice( 1, 18 );

    case 20: 
      return vin.slice( 2, 19 );

    default: 
      return vin
  }
}

/**
 * return true if and only if valid US vin 
 * calculation based on length & checksum:
 * https://stackoverflow.com/questions/26407015/javascript-jquery-vin-validator
 * @param {string} vin 
 */
function isValidVin( vin ) {
  return validate( to17CharVin( vin.trim() ) );

  // source: https://en.wikipedia.org/wiki/Vehicle_identification_number#Example_Code
  function transliterate( c ) {
    return '0123456789.ABCDEFGH..JKLMN.P.R..STUVWXYZ'.indexOf( c ) % 10;
  }

  function getCheckDigit( vin ) {
    const map = '0123456789X';
    const weights = '8765432X098765432';
    let sum = 0;
    for ( let i = 0; i < 17; ++i ) { sum += transliterate( vin[i] ) * map.indexOf( weights[i] ); }
    return map[sum % 11];
  }

  function validate( vin ) {
    if ( vin.length !== 17 ) return false;
    return getCheckDigit( vin ) === vin[8];
  }
}

exports.isTracker = isTracker
exports.isAlphaNum = isAlphaNum
exports.to17CharVin = to17CharVin
exports.isValidVin = isValidVin
