import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Flex, Text } from '@leverege/ui-elements'
import { Config } from '../../config'
import { Constants, LoginActions } from '../../actions'

import S from './Header.less'

class Header extends React.Component {

  static contextTypes = {
    store : PropTypes.object
  }

  onClick = () => {
    const { dispatch } = this.props
    dispatch( LoginActions.logout() )
  }

  render() {
    return (
      <Flex className={S.header} justify="space-between" align="center">
        <img alt="Cox2M Logo" className={S.logo} src={`images/${Constants.logos[Config.PROJECT_NAME]}`} />
        <Text onClick={this.onClick} className={S.logOut}>Log Out</Text>
      </Flex>
    )
  }
}

export default connect( )( Header )
