import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { TextInput, Content, Flex, Text } from '@leverege/ui-elements'
import { UI, PinpointActions, Constants } from '../../actions'
import S from './ScanTracker.less'
import ScanFooter from '../scanFooter/ScanFooter'
import Util from '../../util'

class ScanTracker extends React.Component {

  static contextTypes = {
    store : PropTypes.object
  };

  static propTypes = {
    ui : PropTypes.object.isRequired
  } 

  changeTracker = ( tValue ) => {
    const { dispatch } = this.props
    const { ui : { tracker : { showError } } } = this.props
    const { error, value } = tValue
    dispatch( UI.set( 'tracker', { error, value : value.toUpperCase(), showError } ) )

    // if ( tValue.isReturn ) this.onNext( null, tValue )
  }
  
  onBackPair = () => {
    const { dispatch } = this.props
    dispatch( push( '/' ) )
  }

  onBackUnpair = () => {
    const { dispatch } = this.props
    dispatch( push( '/' ) )
  }

  onNext = ( evt, overrideValue = {} ) => {
    const { dispatch } = this.props
    const { ui } = this.props
    const { error, value } = overrideValue

    const finalValue = value || ui.tracker.value
    const finalError = error != null ? error : ui.tracker.error || !Util.Validator.isTracker( finalValue )

    dispatch( UI.set( 'tracker', { value : finalValue.toUpperCase(), error : finalError, showError : true } ) )

    if ( !finalError ) {
      if ( ui.mode === Constants.MODE_UNPAIR ) {
        dispatch( PinpointActions.associate() ).then( console.log )
      } else {
        dispatch( PinpointActions.checkRFABeforeAssociation() )
      }
    } 
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(
      UI.set(
        'scanHeader',
        {
          variant : '',
          text : 'Scan Tracker ID',
          cancel : true
        }
      )
    )
  }

  render() {
    const { ui, pp } = this.props
    const { mode, tracker : { value, error, showError } } = ui
    const loading = ( pp.status === Constants.ASSOC_PENDING )

    return (
      <Content>
        <Content.Area>
          <Flex direction="column" align="flex-start" className={S.body}>
            <Flex className={S.noPadding}>
              <Text variant="scanText">
                Click below to scan tracker ID:
              </Text>
            </Flex>
            <TextInput 
              acceptKey={Util.Validator.isAlphaNum}  
              variant="scannable" 
              alwaysFireOnReturn 
              allowError 
              validator={Util.Validator.isTracker} 
              focus 
              hint="Tracker ID..." 
              className={S.tracker} 
              value={value} 
              onChange={this.changeTracker} />
            <Text className={S.errorText}>
              {showError && error && '*Invalid tracker. Please try again.'}
            </Text>
          </Flex>
        </Content.Area>
        <Content.Footer className={S.footer}>
          <ScanFooter
            onBack={mode === Constants.MODE_PAIR ? this.onBackPair : this.onBackUnpair}
            onNext={this.onNext}
            loading={loading}
            progress={mode === Constants.MODE_PAIR ? { progress : 0, total : 3 } : { progress : 0, total : 2 }} />
        </Content.Footer>
      </Content>
    )
  }
}

export default connect( 
  state => ( {
    ui : state.ui,
    pp : state.pinpoint.assoc
  } )
)( ScanTracker )
