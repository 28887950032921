import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import XCircle from 'react-feather/dist/icons/x-circle'
import AlertTriangle from 'react-feather/dist/icons/alert-triangle'
import { Flex, Text } from '@leverege/ui-elements'
import { UI, Constants } from '../../actions'

import S from './ScanHeader.less'

class Header extends React.Component {

  static contextTypes = {
    store : PropTypes.object
  };

  static propTypes = {
    ui : PropTypes.object.isRequired
  } 
  
  onCancel = () => {
    const { dispatch } = this.props
    // TODO : add clearing of fields
    dispatch( UI.multiSet( {
      tracker : {
        value : '',
        error : null,
        showError : false
      },
      asset : {
        value : '',
        error : null,
        showError : false
      }
    } ) )
    dispatch( push( '/' ) )
  }

  render() {
    const { ui } = this.props
    const { scanHeader, mode } = ui
    const { variant } = scanHeader

    let cancel
    if ( scanHeader.cancel ) {
      cancel = (
        <Text className={S.cancel} onClick={this.onCancel}>
          Cancel
        </Text>
      )
    }

    const modeText = (
      <Text variant="modeHeader">
        { mode === Constants.MODE_PAIR ? 'Pair Tracker' : 'Unpair Tracker' }
      </Text>
    )

    let headerContent
    if ( variant !== 'error' && variant !== 'rfa' ) {
      headerContent = (
        <Flex className={S.row} flex={2} direction="column" align="flex-start" justify="flex-start">
          {modeText}
          <Text variant="scanHeader">
            {scanHeader.text || 'Scan'}
          </Text>
        </Flex>
      )
    } else if ( variant === 'error' ) {
      headerContent = (
        <Flex className={S.row} direction="column" align="flex-start" justify="flex-start">
          {modeText}
          <Flex direction="row" className={S.noSpace}>
            <XCircle size={22} />
            <Text variant="scanHeader">
              {scanHeader.text || 'Scan'}
            </Text>
          </Flex>
        </Flex>
      )
    } else if ( variant === 'rfa' ) {
      headerContent = (
        <Flex className={S.row} direction="column" align="flex-start" justify="flex-start">
          {modeText}
          <Flex direction="row" className={S.noSpace}>
            <AlertTriangle size={22} />
            <Text variant="scanHeader">
              {scanHeader.text || 'Scan'}
            </Text>
          </Flex>
        </Flex>
      )
    }

    return (
      <Flex className={classnames( S.header, S[variant] )} direction="row" align="center">
        {headerContent}
        <Flex direction="row" align="center" justify="flex-end">
          {cancel}
        </Flex>
      </Flex>
    )
  }
}

export default connect( 
  state => ( {
    ui : state.ui
  } ) )( Header )
