module.exports = {
  "hierarchy": {
    "small": [
      "popup-small"
    ],
    "contactsUpdateDeletePopup": [
      "popup-contactsUpdateDeletePopup"
    ],
    "user": [
      "popup-user"
    ]
  },
  "props": {
    "default": {
      "icon": "fa fa-check"
    },
    "small": {
      "icon": "fa fa-check"
    },
    "contactsUpdateDeletePopup": {
      "icon": "fa fa-check"
    },
    "user": {
      "icon": "fa fa-check"
    }
  }
}