import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import classnames from 'classnames'
import { Content, Flex, Button, Text } from '@leverege/ui-elements'
import { UI, Constants } from '../../actions'
import S from './AssocSuccess.less'
import ScanFooter from '../scanFooter/ScanFooter'

class AssocSuccess extends React.Component {

  static contextTypes = {
    store : PropTypes.object
  };

  static propTypes = {
    ui : PropTypes.object.isRequired
  };

  componentDidMount() {
    const { ui : { mode } } = this.props
    const { dispatch } = this.props
    const text = mode === Constants.MODE_PAIR ? 'Association Complete' : 'Disassociation Complete'
    dispatch(
      UI.set(
        'scanHeader',
        {
          variant : 'success',
          text,
          cancel : false
        }
      )
    )
  }

  onDone = () => {
    const { dispatch } = this.props
    // TODO : add clearing of fields
    dispatch( UI.multiSet( {
      showModal : false,
      vinInfo : null,
      tracker : {
        value : '',
        error : null,
        showError : false
      },
      asset : {
        value : '',
        error : null,
        showError : false
      }
    } ) )
    dispatch( push( '/' ) )
  }

  onBack = () => {
    const { dispatch } = this.props
    dispatch( push( '/tracker' ) )
  }

  renderTrackerHealth() {
    const { ui : { mode }, trackerHealth } = this.props
    return ( mode === Constants.MODE_UNPAIR && trackerHealth ) && ( 
      <Flex className={S.row} direction="row" justify="flex-start" align="flex-start">
        <Text className={S.label} style={{ 'padding-right' : '5px' }}>
          Tracker Health:
        </Text>
        <Text className={trackerHealth === Constants.TRACKER_HEALTHS.RFA ? S.trackerHealthRFA : ''}>
          {trackerHealth}
        </Text>
      </Flex>
    )
  }

  render() {
    const { ui } = this.props
    const { mode } = ui

    return (
      <Content>
        <Content.Area>
          <Flex direction="column" className={S.container}>
            <Flex className={S.row} direction="row" justify="flex-start">
              <Text className={S.summaryText}>
                Device Summary
              </Text>
            </Flex>
            <Flex className={S.row} direction="column" justify="flex-start" align="flex-start">
              <Text className={S.label}>
                Tracker ID:
              </Text>
              <Text className={S.autoRight}>
                {ui.tracker.value}
              </Text>
            </Flex>
            {
              this.renderTrackerHealth()
            }
            {
              ui.asset.value && (
                <Flex className={S.row} direction="column" justify="flex-start" align="flex-start">
                  <Text className={S.label}>
                  VIN Number:
                  </Text>
                  <Text className={S.autoRight}>
                    {ui.asset.value}
                  </Text>
                </Flex>
              )
            }
            <Button onClick={this.onDone} className={classnames( S.actionButton, S.blue )}>
              Done
            </Button>
          </Flex>
        </Content.Area>
        <Content.Footer className={S.footer}>
          <ScanFooter onBack={this.onBack} showBack={false} showNext={false} progress={mode === Constants.MODE_PAIR ? { progress : 2, total : 3 } : { progress : 1, total : 2 }} />
        </Content.Footer>
      </Content>
    )
  }
}

const mapStateToProps = ( state ) => {
  return {
    ui : state.ui,
    rfaState : state.pinpoint.rfa,
    trackerHealth : state.ui.trackerHealth
  }
}

export default connect( mapStateToProps )( AssocSuccess )
