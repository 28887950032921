module.exports = {
  "hierarchy": {
    "bottom": [
      "tabPane-bottom"
    ],
    "contacts": [
      "tabPane-contacts"
    ]
  },
  "props": {
    "default": {},
    "bottom": {},
    "contacts": {}
  }
}