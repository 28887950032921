module.exports = {
  "hierarchy": {
    "filterControls": [
      "pane-filterControls"
    ],
    "filter": [
      "pane-filter"
    ],
    "detailBlock": [
      "pane-detailBlock"
    ],
    "detailBlockHeader": [
      "pane-detailBlockHeader"
    ],
    "detailBlockTitle": [
      "pane-detailBlockHeader",
      "pane-detailBlockTitle"
    ],
    "settingsCards": [
      "pane-settingsCards"
    ],
    "detailBlockRowOdd": [
      "pane-detailBlockHeader",
      "pane-detailBlockTitle",
      "pane-detailBlockRowOdd"
    ],
    "detailBlockRowEven": [
      "pane-detailBlockHeader",
      "pane-detailBlockTitle",
      "pane-detailBlockRowEven"
    ],
    "emptyBlock": [
      "pane-emptyBlock"
    ],
    "detailRfa": [
      "pane-detailRfa"
    ],
    "detailStatus": [
      "pane-detailStatus"
    ],
    "targetCard": [
      "pane-targetCard"
    ],
    "mobileMenu": [
      "pane-mobileMenu"
    ],
    "dialog": [
      "pane-dialog"
    ],
    "mapEditor": [
      "pane-mapEditor"
    ],
    "container": [
      "pane-container"
    ],
    "selectableItem": [
      "pane-selectableItem"
    ],
    "modal": [
      "pane-modal"
    ],
    "card": [
      "pane-card"
    ],
    "genericInput": [
      "pane-genericInput"
    ],
    "loginPane": [
      "pane-loginPane"
    ],
    "zoomPane": [
      "pane-zoomPane"
    ],
    "lotSettingsCard": [
      "pane-settingsCards",
      "pane-lotSettingsCard"
    ],
    "timeline": [
      "pane-timeline"
    ],
    "drillDownDetailBlock": [
      "pane-timeline",
      "pane-drillDownDetailBlock"
    ],
    "vehicleType": [
      "pane-vehicleType"
    ],
    "oddDrillDown": [
      "pane-detailBlockHeader",
      "pane-detailBlockTitle",
      "pane-detailBlockRowOdd",
      "pane-oddDrillDown"
    ],
    "lotSettingsCardNoPad": [
      "pane-settingsCards",
      "pane-lotSettingsCard",
      "pane-lotSettingsCardNoPad"
    ],
    "graphTile": [
      "pane-graphTile"
    ],
    "editingForm": [
      "pane-editingForm"
    ],
    "dialogHeader": [
      "pane-dialogHeader"
    ],
    "dialogContent": [
      "pane-dialogHeader",
      "pane-dialogContent"
    ],
    "dialogFooter": [
      "pane-dialogHeader",
      "pane-dialogFooter"
    ],
    "dialogBody": [
      "pane-dialogHeader",
      "pane-dialogBody"
    ],
    "shadowPane": [
      "pane-shadowPane"
    ],
    "lotSettingsCardFull": [
      "pane-settingsCards",
      "pane-lotSettingsCard",
      "pane-lotSettingsCardFull"
    ],
    "ruleCard": [
      "pane-ruleCard"
    ],
    "vanilla": [
      "pane-vanilla"
    ],
    "ruleCardHeader": [
      "pane-vanilla",
      "pane-ruleCardHeader"
    ],
    "ruleCardBody": [
      "pane-vanilla",
      "pane-ruleCardHeader",
      "pane-ruleCardBody"
    ],
    "ruleCardFooter": [
      "pane-vanilla",
      "pane-ruleCardHeader",
      "pane-ruleCardFooter"
    ],
    "ruleCardClosed": [
      "pane-ruleCard",
      "pane-ruleCardClosed"
    ],
    "exceptionDetail": [
      "pane-exceptionDetail"
    ],
    "exceptionReason": [
      "pane-filterControls",
      "pane-exceptionReason"
    ],
    "ruleFormContainer": [
      "pane-vanilla",
      "pane-ruleFormContainer"
    ],
    "exceptionTimeline": [
      "pane-timeline",
      "pane-exceptionTimeline"
    ],
    "exceptionItem": [
      "pane-exceptionItem"
    ],
    "exceptionDetailMobile": [
      "pane-exceptionDetailMobile"
    ]
  },
  "props": {
    "default": {},
    "filterControls": {},
    "filter": {},
    "detailBlock": {},
    "detailBlockHeader": {},
    "detailBlockTitle": {},
    "settingsCards": {},
    "detailBlockRowOdd": {},
    "detailBlockRowEven": {},
    "emptyBlock": {},
    "detailRfa": {},
    "detailStatus": {},
    "targetCard": {},
    "mobileMenu": {},
    "dialog": {},
    "mapEditor": {},
    "container": {},
    "selectableItem": {},
    "modal": {},
    "card": {},
    "genericInput": {},
    "loginPane": {},
    "zoomPane": {},
    "lotSettingsCard": {},
    "timeline": {},
    "drillDownDetailBlock": {},
    "vehicleType": {},
    "oddDrillDown": {},
    "lotSettingsCardNoPad": {},
    "graphTile": {},
    "editingForm": {},
    "dialogHeader": {},
    "dialogContent": {},
    "dialogFooter": {},
    "dialogBody": {},
    "shadowPane": {},
    "lotSettingsCardFull": {},
    "ruleCard": {},
    "vanilla": {},
    "ruleCardHeader": {},
    "ruleCardBody": {},
    "ruleCardFooter": {},
    "ruleCardClosed": {},
    "exceptionDetail": {},
    "exceptionReason": {},
    "ruleFormContainer": {},
    "exceptionTimeline": {},
    "exceptionItem": {},
    "exceptionDetailMobile": {}
  }
}