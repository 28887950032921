import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import { TextInput, Content, Flex, Text, DataBlock } from '@leverege/ui-elements'
import AlertTriangle from 'react-feather/dist/icons/alert-triangle'
import { PinpointActions, UI } from '../../actions'
import S from './ScanAsset.less'
import ScanFooter from '../scanFooter/ScanFooter'
import Modal from '../modal/Modal'
import Util from '../../util'

const vinFields = [
  { name : 'Make', accessor : item => item.make },
  { name : 'Model', accessor : item => item.model },
  { name : 'Year', accessor : item => item.modelYear }

]

class ScanAsset extends React.Component {

  constructor( props ) {
    super( props )

    this.state = {
      loading : false
    }
  }

  static propTypes = {
    ui : PropTypes.object.isRequired
  }

  changeAsset = ( aValue ) => {
    const { dispatch } = this.props
    const { ui : { asset : { showError } } } = this.props
    const { error, value } = aValue
    dispatch( UI.set( 'asset', { error, value : value.toUpperCase(), showError } ) )

    // if ( aValue.isReturn ) this.onNext( null, aValue )
  }

  onBack = () => {
    const { dispatch } = this.props
    dispatch( push( '/tracker' ) )
  }

  onNext = ( evt, overrideValue = {} ) => {
    const { dispatch, allowInvalidVin } = this.props
    const { ui } = this.props
    const { error, value } = overrideValue
    if ( allowInvalidVin ) {
      dispatch( UI.multiSet( {
        showModal : true,
        vinInfo : { make : '', model : '', year : '' }
      } ) )
    } else {
      const finalValue = value || ui.asset.value
      const finalError = error != null ? error : ui.asset.error || !Util.Validator.isValidVin( finalValue )
  
      dispatch( UI.set( 'asset', { error : finalError, value : finalValue.toUpperCase(), showError : true } ) )
  
      dispatch( PinpointActions.getVinInfo( finalValue.trim() ) )
        .then( ( res ) => {
          dispatch( UI.multiSet( {
            showModal : true,
            vinInfo : res
          } ) )
        } )
    }
    // } )

    // if ( !finalError ) {
    //   // store.dispatch( push( '/tracker' ) )
    //   dispatch( PinpointActions.associate() ).then( console.log )
    // } else if ( allowInvalidVin && finalValue.trim() ) {
    //   // show confirmation modal
    //   dispatch( UI.set( 'showModal', true ) )
    // }
  }

  onModalCancel = () => {
    const { dispatch } = this.props
    dispatch( UI.multiSet( {
      showModal : false,
      vinInfo : null
    } ) )
  }

  onModalConfirm = () => {
    const { dispatch } = this.props
    this.setState( {
      loading : true
    } )
    dispatch( UI.set(
      'showModal', false ) )
    dispatch( PinpointActions.associate() ).then( console.log )
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(
      UI.set(
        'scanHeader',
        {
          variant : '',
          text : 'Scan VIN',
          cancel : true
        }
      )
    )
  }

  render() {
    const { ui : { asset : { value, showError }, showModal, vinInfo }, allowInvalidVin } = this.props
    let { ui : { asset : { error } } } = this.props
    const { loading } = this.state
    error = allowInvalidVin ? false : error

    let modal;
    if ( showModal ) {
      modal = (
        <Modal 
          confirmLabel="Submit VIN"
          onConfirm={this.onModalConfirm}
          disableConfirm={loading || error || !vinInfo}
          onCancel={this.onModalCancel} 
          cancelLabel="Cancel">
          <Flex align={loading ? 'center' : 'flex-start'} justify="center" style={{ width : '100%', height : '100%' }}>
            {!loading ? (
              <React.Fragment>
                <AlertTriangle color={error || !vinInfo ? '#E62700' : null} size={72} />
                <div className={S.modalContent}>
                  <Text variant="modalHeader">VIN Details</Text>
                  <Text variant="tcMakeModel">{value}</Text>
                  <Text variant="modalBody">
                    {( error || !vinInfo ) && (
                      <React.Fragment>
                        <p>This looks like an invalid VIN.</p>
                        <p>Please cancel and retry. Your tracker cannot be associated at this time.</p>
                      </React.Fragment>
                    )}
                    {vinInfo && !error ? (
                      <DataBlock variant="vinInfo" title="VIN Info" fields={vinFields} item={vinInfo} />
                    ) : (
                      <Text variant="errorText">No information available for this VIN</Text>
                    )}
                  </Text>
                </div>
              </React.Fragment>
            ) : <div>Pairing request processing...</div>}
          </Flex>
        </Modal>
      )
    }

    const errMsg = allowInvalidVin ? '*Warning: Not a valid 1983+ US VIN' : '*Invalid VIN. Please try again.'
    
    return (
      <Content>
        {modal}
        <Content.Area>
          <Flex direction="column" align="flex-start" className={S.body}>
            <Flex className={S.noPadding}>
              <Text className={S.scanText}>
                Click below to scan VIN:
              </Text>
            </Flex>
            <TextInput 
              acceptKey={Util.Validator.isAlphaNum} 
              variant="scannable" 
              alwaysFireOnReturn 
              validator={Util.Validator.isValidVin} 
              allowError 
              focus 
              hint="VIN..." 
              className={S.asset} 
              value={value} 
              onChange={this.changeAsset} />
            <Text variant="errorText">{showError && error && errMsg }</Text>
          </Flex>
        </Content.Area>
        <ScanFooter 
          onBack={this.onBack} 
          onNext={this.onNext} 
          progress={{ progress : 1, total : 3 }} />
      </Content>
    )
  }
}

export default connect( 
  state => ( {
    ui : state.ui,
    rfaState : state.pinpoint.rfa,
    vinInfo : state.vinInfo || {}
  } )
)( ScanAsset )
