import React from 'react'
import PropTypes from 'prop-types'
import { Content, Flex, Text } from '@leverege/ui-elements'
import ChevronLeft from 'react-feather/dist/icons/chevron-left'
import ChevronRight from 'react-feather/dist/icons/chevron-right'
import { FadingCircle } from 'better-react-spinkit'
import { UI } from '../../actions'
import S from './ScanFooter.less'

export default class ScanTracker extends React.Component {

  static propTypes = {
    progress : PropTypes.object.isRequired,
    onBack : PropTypes.func,
    onNext : PropTypes.func,
    showBack : PropTypes.bool,
    showNext : PropTypes.bool
  };

  static defaultProps = {
    onBack : () => console.log( 'on back not implemented' ),
    onNext : () => console.log( 'on next not implemented' ),
    showNext : true,
    showBack : true
  }

  static contextTypes = {
    store : PropTypes.object,
  };

  changeTracker = ( value ) => {
    const { dispatch } = this.props
    dispatch( UI.set( 'tracker', value ) )
  }

  renderNextButton = ( showNext, onNext, loading ) => {
    if ( showNext ) {
      const label = loading ? <FadingCircle /> : <Text>Next</Text>
      const onClick = loading ? () => {} : onNext
      return (
        <Flex justify="center" className={S.actionArrow} onClick={onClick}>
          { label }
          <ChevronRight size={28} />
        </Flex>
      )
    }
    return <Flex justify="center" className={S.actionArrow} />
  }

  render() {
    const { onBack, onNext, progress, showBack, showNext, loading } = this.props

    const progressCircles = []
    for ( let i = 0; i < progress.total; i++ ) {
      if ( i === progress.progress ) {
        progressCircles.push(
          <i className={`${S.circleFull} fa fa-circle`} key={i} />
        )
      } else {
        progressCircles.push(
          <i className={`${S.circleEmpty} fa fa-circle`} key={i} />
        )
      }
    }

    const next = this.renderNextButton( showNext, onNext, loading )

    return (
      <Content.Footer className={S.footer}>
        <Flex className={S.container} justify="space-between">
          {
            showBack ? (
              <Flex justify="center" className={S.actionArrow} onClick={onBack}>
                <ChevronLeft size={28} />
                <Text>
                  Back
                </Text>
              </Flex>
            ) :
              <Flex justify="center" className={S.actionArrow} />
          }
          <Flex className={S.center} justify="center">
            {progressCircles}
          </Flex>
          { next }
        </Flex>
      </Content.Footer>
    )
  }
}
