import Constants from '../actions/Constants'

const initialState = {
  status : null,
  profile : null, // lsp,
  idToken : null, // localStorage.getItem( 'id_token' ),
  targetLocation : localStorage.getItem( 'targetLocation' ),
  showLogin : false,
}

function login( state = initialState, action ) {
  switch ( action.type ) {
    case Constants.LOGIN_PENDING:
      return { status : Constants.LOGIN_PENDING, profile : null, idToken : null }

    case Constants.VERIFY_PENDING:
      return { status : Constants.VERIFY_PENDING, profile : null, idToken : null }

    case Constants.LOGIN_SUCCESS: {
      localStorage.setItem( 'profile', JSON.stringify( action.profile ) )
      localStorage.setItem( 'id_token', action.profile.idToken )
      return {
        status : Constants.LOGIN_SUCCESS,
        profile : action.profile,
        idToken : action.idToken,
      }
    }

    case Constants.LOGIN_FAILURE:
      localStorage.removeItem( 'profile' )
      localStorage.removeItem( 'id_token' )
      return { status : Constants.LOGIN_FAILURE, profile : null, idToken : null, error : action.error }

    case Constants.LOGOUT:
      localStorage.removeItem( 'id_token' )
      localStorage.removeItem( 'profile' )

      return { status : null, profile : null, idToken : null }


    default:
      return state
  }
}

export default login
