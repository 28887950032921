module.exports = {
  "hierarchy": {
    "small": [
      "checkbox-small"
    ],
    "smallest": [
      "checkbox-small",
      "checkbox-smallest"
    ]
  },
  "props": {
    "default": {
      "iconOn": "fa fa-check"
    },
    "small": {
      "iconOn": "fa fa-check"
    },
    "smallest": {
      "iconOn": "fa fa-check"
    }
  }
}