module.exports = {
  "hierarchy": {
    "small": [
      "label-small"
    ],
    "filterOption": [
      "label-filterOption"
    ],
    "paneTitle": [
      "label-paneTitle"
    ],
    "paneTitleSmall": [
      "label-paneTitle",
      "label-paneTitleSmall"
    ],
    "inputLabel": [
      "label-inputLabel"
    ],
    "secondaryHeader": [
      "label-paneTitle",
      "label-secondaryHeader"
    ],
    "selectableItem": [
      "label-selectableItem"
    ],
    "formLabel": [
      "label-formLabel"
    ],
    "modalHeader": [
      "label-modalHeader"
    ],
    "contactFormLabel": [
      "label-contactFormLabel"
    ],
    "lotEditingPaneTitle": [
      "label-paneTitle",
      "label-lotEditingPaneTitle"
    ],
    "secondLotEditingHeader": [
      "label-paneTitle",
      "label-paneTitleSmall",
      "label-secondLotEditingHeader"
    ],
    "ruleLabel": [
      "label-ruleLabel"
    ],
    "paneTitleNoPad": [
      "label-paneTitle",
      "label-paneTitleNoPad"
    ],
    "paneTitleSmallNoPad": [
      "label-paneTitle",
      "label-paneTitleNoPad",
      "label-paneTitleSmallNoPad"
    ],
    "noPad": [
      "label-contactFormLabel",
      "label-noPad"
    ],
    "error": [
      "label-contactFormLabel",
      "label-error"
    ],
    "exceptionsLabel": [
      "label-paneTitle",
      "label-paneTitleNoPad",
      "label-paneTitleSmallNoPad",
      "label-exceptionsLabel"
    ],
    "vinLink": [
      "label-vinLink"
    ]
  },
  "props": {
    "default": {},
    "small": {},
    "filterOption": {},
    "paneTitle": {},
    "paneTitleSmall": {},
    "inputLabel": {},
    "secondaryHeader": {},
    "selectableItem": {},
    "formLabel": {},
    "modalHeader": {},
    "contactFormLabel": {},
    "lotEditingPaneTitle": {},
    "secondLotEditingHeader": {},
    "ruleLabel": {},
    "paneTitleNoPad": {},
    "paneTitleSmallNoPad": {},
    "noPad": {},
    "error": {},
    "exceptionsLabel": {},
    "vinLink": {}
  }
}